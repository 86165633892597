import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Datasheet from "../../../../content/produk/controller/videoprocessor/NOVASTAR MCTRL600/datasheet-novastar-mctrl6000.pdf";
import Content from "../../../../content/produk/controller/videoprocessor/NOVASTAR MCTRL600/Product-Page.json";
import ProductImage from "../../../../images/product/controller/videoprocessor/NOVASTAR MCTRL600/Product-Summary-Novastar-MCTRL600.jpg";
const novastarmctrl = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Video Processor Novastar MCTRL600</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            MCTRL600 adalah controller independen Novastar.
                            Maximum resolusi untuk sebuah controller adalah
                            1920&times;1200@60Hz. Controller dapat ditumpuk
                            melalui port UART untuk kendali yang sama. MCTRL600
                            dapat dipakai untuk rental dan fixed fields seperti
                            acara live, monitoring sekuriti dan tempat olah raga
                        </p>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

novastarmctrl.propTypes = {
    siteTitle: PropTypes.string,
};

novastarmctrl.defaultProps = {
    siteTitle: ``,
};
export default novastarmctrl;
